<template>
  <div :class="placeOrderModal ? 'show-modal' : ''" class="modal-window fixed inset-0 z-10">
    <div class="rounded-lg xl:w-2/5 lg:w-3/5 sm:w-2/3 w-11/12 absolute p-6">
      <a @click="closePlaceOrderModal" class="modal-close">&times;</a>
      <div class="w-full sm:block flex">
        <div class="sm:w-full w-1/5">
          <img src="@/assets/images/hands.svg" class="sm:w-20 w-10 sm:m-auto" alt="">
        </div>
        <div class="w-full modal-content">
          <p class="text-base text-darkblue sm:mt-4 modal-title self-center header" style="letter-spacing: 0.04em;">
            {{$t('You\'ve successfully placed an order with:')}}
          </p>
          <div class="mt-2 flex modal-subtitle">
            <span class="dot self-center"></span>
            <hr class="self-center hr mr-2">
            <p class="text-blue font-medium text-base">{{ merchant.name }}</p>
            <hr class="self-center hr sm:block hidden ml-2">
            <span class="dot self-center sm:block hidden"></span>
          </div>
          <p class="text-base text-darkblue mt-6 modal-title self-center header" style="letter-spacing: 0.04em;">
            {{$t('To be delivered to:')}}
          </p>
          <div class="mt-2 flex modal-subtitle">
            <span class="dot self-center sm:block hidden"></span>
            <hr class="self-center hr sm:block hidden">
            <p class="text-blue font-medium text-sm">{{ customerAddress.line_1 }}, {{ customerAddress.line_2 }}</p>
            <hr class="self-center hr sm:block hidden">
            <span class="dot self-center sm:block hidden"></span>
          </div>
          <div class="mt-4 flex modal-subtitle">
            <p class="cursor-pointer text-blue font-medium text-base" @click="openTrackingPortal">{{$t('Track Your Order')}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['placeOrderModal', 'merchant', 'customerAddress', 'order'],
  data () {
    return {
    }
  },
  methods: {
    openTrackingPortal () {
      if (window.location.href.includes('staging')) {
        window.location.href = `https://go.staging.shipblu.com/${this.$i18n.locale}/${this.order.tracking_number}`
      } else {
        window.location.href = `https://go.shipblu.com/${this.$i18n.locale}/${this.order.tracking_number}`
      }
    },
    closePlaceOrderModal () {
      this.$emit('placeOrderModal', false)
      window.location.href = 'https://shipblu.com'
    }
  }
}
</script>