let common = {
  notifyResponseError (error, Vue) {
    let message = ''
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        message = error.response.data.detail
      } else if ((error.response.status === 400 || error.response.status === 404) && error.response.data.message) {
        message = error.response.data.message
      } else if (error.response.status === 404) {
        Vue.$router.push({
          name: 'page-error-404'
        }).catch(() => {})
        message = 'Not found!'
      } else if (error.response.status === 400) {
        let key = 1
        let keyItem = 1
        if (error.response.data[0] && error.response.data[0].non_field_errors) {
          for (key in error.response.data[0].non_field_errors) {
            if (error.response.data[0].non_field_errors.hasOwnProperty(key)) {
              message = `${error.response.data[0].non_field_errors[key]}`
              key++
            }
          }
        } else if (error.response.data) {
          for (key in error.response.data) {
            if (typeof (error.response.data[key]) === 'object') {
              for (keyItem in error.response.data[key]) {
                message = `${typeof (error.response.data[key][keyItem]) === 'object' ?keyItem : ''} ${error.response.data[key][keyItem]}`
              }
            } else {
              if (error.response.data.hasOwnProperty(key)) {
                message = `${error.response.data[key]}`
              }
            }
          }
        } else {
          for (key in error.response.data) {
            if (error.response.data.hasOwnProperty(key)) {
              message = `${key}: ${error.response.data[key]}`
              key++
            }
          }
        }
      } else {
        message = 'Error. Please try again!'
      }
      return message
    }
  }
}
export default common
