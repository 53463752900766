<template>
  <div class="m-auto" ref="main">
    <div :class="stopLoading ? '' : 'show-modal loading-window fixed inset-0 z-20'">
      <div id="loading"></div>
    </div>
    <div v-show="notify">
      <div class="alert error mx-5">
        <p class="inner text-left">
          {{message}}
        </p>
      </div>
    </div>
    <div v-if="renderPage" class="main lg:px-32 sm:py-11 md:px-16 p-6">
      <div class="grid grid-cols-2 gap-6">
        <div class="col-span-1 flex">
          <img class="lg:w-60 sm:w-52 w-44" v-bind:src="$i18n.locale === 'en' ? EnLogo : ArLogo" alt="ShipBlu Logo">
        </div>
        <div class="col-span-1 flex self-center social-icons justify-end">
          <a href="https://www.facebook.com/shipblu" target="_blank">
            <img class="facebook icon" src="@/assets/images/facebook.svg" alt="">
          </a>
          <a href="https://mobile.twitter.com/_shipblu" target="_blank">
            <img class="twitter icon" src="@/assets/images/twitter.svg" alt="">
          </a>
        </div>
      </div>
      <div class="lg:block hidden">
        <languages />
      </div>
      <div class="card mt-6">
        <div class="lg:hidden block">
          <languages />
        </div>
        <div class="md:grid xl:grid-cols-6 lg:grid-cols-4 lg:gap-10 lg:py-18 md:px-18 pt-8 pb-4 px-4">
          <div class="lg:col-span-1 merchant-info">
            <img class="w-10 rounded-full" :src="merchant.logo_url !== '' ? merchant.logo_url : DefaultMerchantLogo" alt="">
            <p class="mt-4 capitalize font-medium text-lg header text-darkblue">{{merchant.name}}</p>
            <p class="lg:inline-block hidden mt-4 mb-1 border w-12"></p>
            <div>
              <span class="mr-1 text-blue text-sm">{{$t('Thank you for choosing us!')}}</span>
              <span class="material-icons favorite-icon">favorite_border</span>
            </div>
            <div class="lg:block hidden">
              <p class="lg:inline-block hidden mt-4 mb-1 border w-full"></p>
              <p class="mt-3 capitalize font-medium text-base header text-darkblue">{{$t('Having your own business?')}}</p>
              <div class="flex mt-2">
                <img src="@/assets/images/light.svg" alt="">
                <a href="https://shipblu.com/get-started-with-shipblu/?ref=magic" target="_blank" class="ml-2 text-blue text-sm underline self-end cursor-pointer">{{$t('Start shipping with us now..')}}</a>
              </div>
            </div>      
            <p class="lg:hidden inline-block my-4 border w-12"></p>
          </div>
          <div class="xl:col-span-5 lg:col-span-3">
            <div class="merchant-info">
              <p class="font-semibold text-xl text-blue header">{{$t('New order')}}</p>
              <p class="mt-3 lg:mx-0 m-auto text-sm text-gray xl:w-1/2 md:w-3/4 w-11/12">{{$t('Kindly fill in the form below to make sure your order is placed and on its way to you..')}}</p>
            </div>
            <div class="mt-7">
              <div class="sm:grid sm:grid-cols-1 sm:gap-4 block error-span">
                <div class="sm:col-span-1">
                  <input v-model="customer.full_name" :disabled="merchant.customer_name" type="text" class="input-form w-full" name="full name" v-validate="'required'" :placeholder="$t('Full name')+' *'">
                  <span class="text-red text-sm" v-show="errors.has('full name')">{{ errors.first('full name') }}</span>
                </div>
              </div>
              <div class="sm:grid sm:grid-cols-2 sm:gap-4 mt-4 block error-span">
                <div class="sm:col-span-1">
                  <input v-model="customer.phone" :disabled="merchant.customer_phone" type="text" class="input-form w-full" name="phone number" v-validate="{ required: true, regex: /^01[0,1,2,5]\d{8}$/ }" :placeholder="$t('Phone number')+' *'">
                  <span class="text-red text-sm" v-show="errors.has('phone number')">{{ errors.first('phone number') }}</span>
                </div>
                <div class="sm:col-span-1 sm:mt-0 mt-4">
                  <input v-model="customer.email" type="text" class="input-form w-full" name="email" v-validate="'email'" :placeholder="$t('Email address')">
                  <span class="text-red text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>
                </div>
              </div>
              <div class="xl:grid xl:grid-cols-2 xl:gap-4 mt-4 block error-span">
                <div class="xl:col-span-1">
                  <input v-model="customer.address.line_1" type="text" class="input-form w-full" name="address 1" v-validate="'required'" :placeholder="$t('Customer Address 1')+' *'">
                  <span class="text-red text-sm" v-show="errors.has('address 1')">{{ errors.first('address 1') }}</span>
                </div>
                <div class="xl:col-span-1 xl:mt-0 mt-4">
                  <input v-model="customer.address.line_2" type="text" class="input-form w-full" name="address 2" v-validate="'required'" :placeholder="$t('Customer Address 2')+' *'">
                  <span class="text-red text-sm" v-show="errors.has('address 2')">{{ errors.first('address 2') }}</span>
                </div>
              </div>
              <div class="xl:grid xl:grid-cols-3 xl:gap-4 block xl:mt-4 error-span">
                <div class="xl:mt-0 mt-4">
                  <v-select autocomplete="nofill" :options="governorates" class="input-form" name="governorate" label="name" item-text="name"
                    v-model="governorate" item-value="id" return-object v-on:input="governorate ? loadCities(`${governorate.id}`) : zones = [], zone = '', cities = [], city = ''"
                    :placeholder="$t('Governorate *')" v-validate="'required'"></v-select>
                  <span class="text-red text-sm" v-show="errors.has('governorate')">{{ errors.first('governorate') }}</span>
                </div>
                <div class="xl:mt-0 mt-4">
                  <v-select autocomplete="nofill" :options="cities" class="input-form" name="city" label="name" item-text="name"
                    v-model="city" item-value="id" return-object v-on:input="city ? loadZones(`${city.id}`) : zones = [], zone = ''"
                    :placeholder="$t('City *')" v-validate="'required'"></v-select>
                  <span class="text-red text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>
                </div>
                <div class="xl:mt-0 mt-4">
                  <v-select autocomplete="nofill" :options="zones" class="input-form xl:mt-0 mt-4" name="zone" label="name" item-text="name"
                    v-model="zone" item-value="id" return-object :placeholder="$t('Zone *')" v-validate="'required'"></v-select>
                  <span class="text-red text-sm" v-show="errors.has('zone')">{{ errors.first('zone') }}</span>
                </div>
              </div>
              <div class="mt-4">
                <input v-model="customer.address.line_3" type="text" class="input-form w-full" :placeholder="$t('Landmark?')">
              </div>
            </div>
            <div class="mt-6">
              <button :disabled="dataSended" :class="dataSended ? 'save-button-disable py-6' : 'save-button'" class="rounded-md flex text-white px-4 font-bold text-lg outline-none w-full relative header" @click="sendData">
                <span v-if="dataSended" class="self-center save-loading"></span>
                <span class="m-auto py-4" v-if="!dataSended">{{$t('Place Order')}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-6 lg:hidden block p-6 text-center">
        <p class="capitalize font-medium text-base header text-darkblue">{{$t('Having your own business?')}}</p>
        <img class="m-auto my-2" src="@/assets/images/light.svg" alt="">
        <a href="https://shipblu.com/get-started-with-shipblu/?ref=magic" target="_blank" class="text-blue text-sm underline cursor-pointer">{{$t('Start shipping with us now..')}}</a>
      </div>
      <place-order-modal :order="order" :placeOrderModal="placeOrderModal" @placeOrderModal="placeOrderModal = $event" :merchant="merchant" :customerAddress="customerAddress"/>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import EnLogo from '@/assets/images/logo-en.svg'
import ArLogo from '@/assets/images/logo-ar.svg'
import DefaultMerchantLogo from '@/assets/images/default-merchant-image.svg'
import PlaceOrderModal from './PlaceOrderModal.vue'
import axios from '../axios.js'
import Languages from './Languages.vue'
import common from '@/assets/utils/common'

export default {
  data () {
    return {
      EnLogo,
      ArLogo,
      DefaultMerchantLogo,
      placeOrderModal: false,
      governorate: '',
      city: '',
      zone: '',
      merchant: {},
      customer: {
        address: {}
      },
      governorates: [],
      cities: [],
      zones: [],
      customerAddress: {},
      message: '',
      errorStatus: '',
      stopLoading: false,
      notify: false,
      what3words: '',
      dataSended: false,
      renderPage: false,
      order: {}
    }
  },
  methods: {
    getData () {
      this.stopLoading = false
      axios.get(`api/v1/magic/${this.$route.params.hash}/`)
        .then((response) => {
          this.merchant = response.data
          this.customer.full_name = this.merchant.customer_name
          this.customer.phone = this.merchant.customer_phone
          document.title = `${this.merchant.name} @ ShipBlu Magic Link`
          this.renderPage = true
          this.stopLoading = true
        })
        .catch((error) => {
          this.errorStatus = error.response.status
          this.stopLoading = true
          this.notify = true
          this.message = common.notifyResponseError(error, this)
        })
    },
    loadGovernorates () {
      this.stopLoading = false
      axios.get('api/v1/magic/geo/governorates/')
        .then((response) => {
          response.data.forEach(item => (item.name !== 'NOGOV' ? this.governorates.push(item) : ''))
          this.stopLoading = true
        })
        .catch((error) => {
          this.errorStatus = error.response.status
          this.stopLoading = true
          this.notify = true
          this.message = common.notifyResponseError(error, this)
        })
    },
    loadCities (governorateID) {
      this.stopLoading = false
      axios.get(`api/v1/magic/geo/governorates/${governorateID}/cities/`)
        .then((response) => {
          this.cities = response.data
          this.stopLoading = true
        })
        .catch((error) => {
          this.errorStatus = error.response.status
          this.stopLoading = true
          this.notify = true
          this.message = common.notifyResponseError(error, this)
        })
    },
    loadZones (cityID) {
      this.stopLoading = false
      axios.get(`api/v1/magic/geo/cities/${cityID}/zones/`)
        .then((response) => {
          this.zones = response.data
          this.stopLoading = true
        })
        .catch((error) => {
          this.errorStatus = error.response.status
          this.stopLoading = true
          this.notify = true
          this.message = common.notifyResponseError(error, this)
        })
    },
    sendData () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.dataSended = true
          const orderCustomer = {
            full_name: this.customer.full_name,
            phone: this.customer.phone,
            email: this.customer.email,
            address: {
              what3words: this.what3words,
              line_1: this.customer.address.line_1,
              line_2: this.customer.address.line_2,
              line_3: this.customer.address.line_3,
              zone: this.zone.id
            }
          }
          if (this.current) {
            orderCustomer.address.latitude = this.current.lat
            orderCustomer.address.longitude = this.current.lng 
          }
          this.customerAddress = orderCustomer.address
          axios.post(`api/v1/magic/${this.$route.params.hash}/?ref=magic`,  {customer: orderCustomer})
            .then((response) => {
              this.placeOrderModal = true
              this.order = response.data
              this.customer = {
                address: {
                  line_1: '',
                  line_2: '',
                  line_3: ''
                }
              }
              this.governorate = ''
              this.city = ''
              this.zone = ''
              this.cities = []
              this.zones = []
              this.stopLoading = true
              this.dataSended = false
              this.$mixpanel.track('MAGIC_ORDER_CREATED', {
                merchant_name: this.merchant.name,
                source: /(android)/i.test(navigator.userAgent)
              })
            })
            .catch((error) => {
              this.errorStatus = error.response.status
              this.stopLoading = true
              this.notify = true
              this.message = common.notifyResponseError(error, this)
            })
        } else {
          const element = this.$refs['main']
          const top = element.offsetTop
          window.scrollTo(0, top)
        }
      })
    },
    showErrorMessage () {
      this.notify = true
    }
  },
  components: {
    vSelect,
    PlaceOrderModal,
    Languages
  },
  created () {
    this.getData()
    this.loadGovernorates()
  }
}
</script>

<style lang="scss" src="@/assets/css/styles.scss">
</style>