<template>
  <div>
    <div class="lg:block hidden">
      <button @click="changeLocale('en')" class="language-buttons w-20" :class="$route.params.lang === 'en' ? 'active-language': 'inactive-language'">
        <span class="text-sm">
          <span class="material-icons text-xl float-left">public</span>
          <span class="mx-1 float-left lang">EN</span>
        </span>
      </button>
      <button @click="changeLocale('ar')" class="language-buttons w-20" :class="$route.params.lang === 'ar' ? 'active-language': 'inactive-language'">
        <span class="text-sm">
          <span class="material-icons text-xl float-left">public</span>
          <span class="mx-1 float-left lang">ع</span>
        </span>
      </button>
    </div>

    <div class="lg:hidden block">
        <button @click="changeLocale('ar')" class="sm-language-button" v-if="$route.params.lang === 'en'">
          <span class="text-sm">
            <span class="material-icons text-xl float-left">public</span>
            <span class="mx-1 float-left lang">ع</span>
          </span>
        </button>
        <button @click="changeLocale('en')" class="sm-language-button" v-else>
          <span class="text-sm">
            <span class="material-icons text-xl float-left">public</span>
            <span class="mx-1 float-left lang">EN</span>
          </span>
        </button>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      
    }
  },
  methods: {
    changeLocale (locale) {
      let dir = ''
      this.$cookies.set('locale', locale, '100y')
      if (locale === 'ar') dir = 'rtl'
      else if (locale === 'en') dir = 'ltr'

      this.$router.push({
        params: {lang: this.$cookies.get('locale')}
      }).catch(() => {})
      this.$i18n.locale = this.$cookies.get('locale')
      document.documentElement.setAttribute('dir', dir)
    }
  }
}
</script>